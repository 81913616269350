<template>
  <section class="wrapper">
    <!-- 2. Bullet points -->
    <div class="bullet-points relative">
      <div class="first-title">
        <span>Tarifas y condiciones</span>
        <h2 class="text-dark">Costos y Comisiones</h2>
      </div>

      <div class="bullet-container">
        <div class="bullet" v-for="bullet in bulletPoints" :key="bullet.title">
          <i class="pi pi-heart"></i>
          <div class="bullet-content">
            <h3>{{ bullet.title }}</h3>
            <p>{{ bullet.description }}</p>
          </div>
        </div>
      </div>

      <Button
        :pt="{
          label: {
            class: 'cta-text',
          },
        }"
        class="cta"
        >Saber más información</Button
      >

      <!-- Background image -->
      <div class="mask-image">
        <div class="overlay"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import Button from "primevue/button";
  const bulletPoints = [
    {
      title: "Registro y evaluación Gratuito",
      description:
        "El registro, evaluación y asesoría de ventas de su marca es totalmente gratis",
    },
    {
      title: "Comisión por venta",
      description:
        "Cobramso un porcentaje de venta dependiendo del volumen mensual del producto",
    },
    {
      title: "Impuestos de importación",
      description:
        "Según los tramites de importación y volumen de las ventas este tipo de impuesto puede ser variable.",
    },
  ];
</script>

<style scoped>
  .first-title span {
    color: var(--text-gray);
    /* Paragraph Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 150% */
    letter-spacing: 5.04px;
    text-transform: uppercase;
  }

  .first-title h2 {
    /* Mobile/display 2 */
    font-family: "Inter";
    font-size: 32px;
    font-weight: 600;
    line-height: 40px; /* 120% */
    margin-top: 8px;
    margin-bottom: 0px;
  }

  /* Bullet Points */
  .bullet-points {
    padding: 64px 24px;
    width: 100%;
  }

  .bullet {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .bullet i {
    font-size: 24px;
    color: var(--primary);
    margin-right: 16px;
  }

  .bullet-content h3 {
    color: var(--primary);
    /* Paragraph Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin: 0;
  }

  .bullet-content p {
    color: var(--primary);
    /* Caption Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 0;
    margin-top: 8px;
  }

  .wrapper {
    background: var(--secondary);
  }

  .cta {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    border: none;
    border-radius: 32px;
    background: var(--primary);

    color: var(--secondary);
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }

  .cta:hover {
    background: #313236 !important;
  }

  .bullet-container {
    padding: 48px 0;
  }

  @media (min-width: 768px) {
    .bullet-container {
      display: flex;
      justify-content: space-between;
      max-width: 1368px;
    }

    .bullet-container .bullet {
      width: 276px;
    }

    .bullet-points {
      padding: 64px 96px;
    }

    .first-title h2 {
      font-size: 40px;
      line-height: 50px;
      font-weight: 600;
    }
  }
</style>
