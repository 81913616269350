<template>
  <div class="split-section" id="about-us">
    <section class="wrapper">
      <div class="first-title">
        <span>Sobre nosotros</span>
        <h2>Nos aliamos con las mejores marcas fetichistas</h2>
        <p>
          Velvetik es un mercado de moda fetichista en línea líder en Estados
          Unidos que cuenta con más de 360 millones de visitas por mes. Ofrece
          una amplia gama de productos de moda de diversas marcas y categorías.
        </p>
        <div class="partner-logos">
          <img
            src="@/assets/images/landing-provider/fetlife-logo.svg"
            alt="Fetlife logo"
          />
          <img
            src="@/assets/images/landing-provider/joyclub-logo.svg"
            alt="Joyclub logo"
          />
        </div>
      </div>
    </section>
    <img
      src="@/assets/images/landing-provider/contact-banner.jpeg"
      alt="Contact us banner"
      class="header-image"
    />
  </div>
</template>

<style scoped>
  .first-title span {
    color: var(--text-gray);
    /* Paragraph Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 150% */
    letter-spacing: 5.04px;
    text-transform: uppercase;
  }

  .first-title h2 {
    /* Mobile/display 2 */
    font-family: "Inter";
    font-size: 32px;
    font-weight: 600;
    line-height: 40px; /* 120% */
    margin-top: 8px;
    margin-bottom: 0;
  }

  .first-title p {
    color: var(--secondary);
    /* Paragraph */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-bottom: 24px;
  }

  .wrapper {
    background: var(--primary);
    padding: 80px 24px;
  }

  .partner-logos {
    display: flex;
  }

  .partner-logos img {
    margin-right: 24px;
  }

  .header-image {
    width: 100%;
    display: block;
  }

  @media (min-width: 768px) {
    .split-section {
      display: flex;
    }

    /* Image and section should have equal with */
    .header-image {
      width: 50%;
    }

    .wrapper {
      background: var(--primary);
      padding: 120px 96px;
    }

    .first-title h2 {
      font-size: 40px;
      line-height: 50px;
      font-weight: 600;
    }
  }
</style>
