<template>
  <section class="wrapper" id="how-it-works">
    <!-- 2. Bullet points -->
    <div class="bullet-points relative">
      <div class="first-title">
        <span>Como funciona</span>
        <h2>Venda con confianza, y de forma inteligente</h2>
      </div>
      <div v-if="!isDesktop" class="step-wrapper">
        <div class="steps" v-for="(step, index) in steps" :key="step.title">
          <div class="step">
            <span>Paso {{ index + 1 }}</span>
            <h3>{{ step.title }}</h3>
            <div class="divider"></div>
            <p>{{ step.description }}</p>
            <img :src="step.illustration" :alt="`Step ${index + 1}`" />
          </div>
        </div>
      </div>
      <div class="step-wrapper" v-else>
        <div
          class="steps"
          :class="{ active: activeIndex === index }"
          v-for="(step, index) in steps"
          :key="step.title"
          @click="activeIndex = index"
        >
          <div class="step">
            <span>Paso {{ index + 1 }}</span>
            <h3>{{ step.title }}</h3>
          </div>
        </div>
      </div>
      <div class="step-display" v-if="isDesktop">
        <div class="left">
          <h3>{{ steps[activeIndex].title }}</h3>
          <p>{{ steps[activeIndex].description }}</p>
        </div>
        <img
          :src="steps[activeIndex].illustration"
          :alt="`Step ${activeIndex + 1}`"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
  import Step1 from "@/assets/images/landing-provider/step-1.svg";
  import Step2 from "@/assets/images/landing-provider/step-2.svg";
  import Step3 from "@/assets/images/landing-provider/step-3.svg";
  import Step4 from "@/assets/images/landing-provider/step-4.svg";
  import { ref, onMounted } from "vue";

  const steps = [
    {
      title: "Registre su marca",
      description:
        "Nuestros expertos evaluaran si características de su marca como: la calidad de sus productos, capacidad de respuesta y otra información necesaria, clasifican para vender en Velvetik. Una vez aprobado, registraremos su marca.",
      illustration: Step1,
    },
    {
      title: "Firme el contrato y cree sus productos",
      description:
        "Una vez aprobado, haremos un acuerdo entre ambas partes y a continuación podrá crear sus productos en la plataforma incluyendo fotografías, videos, descripciones y todo el contenido necesario para promocionar su producto. ",
      illustration: Step2,
    },
    {
      title: "Comience a vender",
      description:
        "Una vez un usuario compra alguno de sus productos, el siguiente paso es prepararlo y enviarlo a estados unidos. Este proceso se hace en conjunto con acompañamiento de Velvetik.",
      illustration: Step3,
    },
    {
      title: "Obtenga sus ganacias",
      description:
        "Al finalizar cada mes haremos una transferencia a su cuenta de banco registrada con las ganancias obtenidas durante ese mes. ",
      illustration: Step4,
    },
  ];

  const isDesktop = ref(false);
  const activeIndex = ref(0);

  onMounted(() => {
    isDesktop.value = window.innerWidth >= 768;
  });
</script>

<style scoped>
  .wrapper {
    padding: 80px 24px;
    background: url("@/assets/images/landing-provider/steps-background.webp")
      rgba(0, 0, 0, 0.8) 50% / cover no-repeat;
    background-blend-mode: color;
  }

  .step {
    margin-bottom: 32px;
    font-family: "Inter";
    cursor: pointer;
  }

  .step span {
    color: #707070;

    /* Caption Small Bold */
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 133.333% */

    margin: 0;
  }

  .step h3 {
    color: var(--secondary);

    /* Paragraph Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */

    margin: 0;

    margin-top: 8px;
  }

  .divider {
    width: 100%;
    height: 2px;
    background-color: var(--secondary);
    margin-top: 24px;
  }

  .step img {
    /* Center the image */
    display: block;
    margin: 24px auto 0;
  }

  .first-title span {
    color: var(--text-gray);
    /* Paragraph Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 150% */
    letter-spacing: 5.04px;
    text-transform: uppercase;
  }

  .first-title h2 {
    /* Mobile/display 2 */
    font-family: "Inter";
    font-size: 32px;
    font-weight: 600;
    line-height: 40px; /* 120% */
    margin-top: 8px;
  }

  @media (min-width: 768px) {
    .step-wrapper {
      display: flex;
      border-bottom: 1px solid #313236;
    }

    .wrapper {
      padding: 120px 96px;
    }

    .step {
      margin-bottom: 24px;
      font-family: "Inter";
    }

    .steps {
      margin-right: 56px;
    }

    .steps.active {
      border-bottom: 2px solid var(--secondary);
    }

    .step-display h3 {
      color: var(--Medium-Grey, #707070);
      /* Heading 3 */
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px; /* 133.333% */
      margin-top: 0;
      margin-bottom: 16px;
    }

    .step-display p {
      /* Paragraph */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */

      max-width: 474px;
    }

    .step-display {
      display: flex;
      justify-content: space-between;
      margin-top: 48px;
    }

    .left {
      width: 50%;
      padding-right: 113px;
    }
    .step-display img {
      width: 50%;
      padding: 0 94px;
      height: 224px;
    }

    .first-title h2 {
      font-size: 40px;
      line-height: 50px;
      font-weight: 600;
    }

    .bullet-points {
      max-width: 1368px;
    }
  }
</style>
