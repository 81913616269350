import { createApp } from "vue";
import App from "./App.vue";

import "/node_modules/primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "@/main.css";
import "@/assets/fonts/fonts.css";

import PrimeVue from "primevue/config";
import PrimeOne from "primevue/themes/primeone";
import Aura from "primevue/themes/primeone/aura";
import { definePreset } from "primevue/themes";

const Noir = definePreset(Aura, {
  semantic: {
    colorScheme: {
      light: {
        primary: {
          color: "#000000",
          inverseColor: "#ffffff",
        },
      },
      dark: {
        primary: {
          color: "#000000",
          inverseColor: "#ffffff",
        },
      },
    },
  },
});

const app = createApp(App);
app.use(PrimeVue, {
  theme: {
    base: PrimeOne,
    preset: Noir,
    options: {
      prefix: "p",
      darkModeSelector: "system",
      cssLayer: false,
    },
  },
});
app.mount("#app");
