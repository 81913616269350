<template>
  <section class="wrapper">
    <div class="first-title">
      <span>Contáctanos</span>
      <h2>Quieres ser parte de la experiencia Velvetik?</h2>
      <a href="https://wa.me/573103088636" target="_blank">
        <button class="cta-contact">Contactar</button>
      </a>
    </div>
  </section>
</template>

<style scoped>
  .first-title span {
    color: var(--text-gray);
    /* Paragraph Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 150% */
    letter-spacing: 5.04px;
    text-transform: uppercase;
  }

  .first-title h2 {
    /* Mobile/display 2 */
    font-family: "Inter";
    font-size: 32px;
    font-weight: 600;
    line-height: 40px; /* 120% */
    margin-top: 8px;
    color: var(--primary);
  }

  .first-title p {
    color: var(--primary);
    /* Paragraph */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-top: 40px;
  }

  .first-title a {
    /* Paragraph Bold */
    font-family: Inter;
    font-weight: 600;
    color: var(--primary);
    text-decoration: none;
  }

  .cta-contact {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 32px;
    background: var(--primary);
    color: var(--Texts, #ececec);
    border: none;

    /* Heading 4 */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }

  .cta-contact:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  .first-title {
    background: var(--secondary);
    padding: 80px 24px;
  }

  @media (min-width: 768px) {
    .first-title {
      padding: 96px 80px;
    }

    .cta-contact {
      padding: 16px 32px;
    }

    .first-title h2 {
      font-size: 40px;
      line-height: 50px;
      font-weight: 600;
    }
  }
</style>
