<template>
  <section class="wrapper" id="why-velvetik">
    <!-- 1. Title and screenshots -->
    <div class="first-title first relative">
      <span>Velvetik Marketplace</span>
      <h2>Una experiencia atractiva y funcional</h2>

      <!-- Three stacked screenshot images -->
      <picture class="screenshot-wrapper">
        <source
          media="(max-width: 768px)"
          srcset="@/assets/images/landing-provider/ss-collection-1-mobile.png"
        />
        <img
          src="@/assets/images/landing-provider/ss-collection-1-desktop.png"
          alt="Velvetik screenshot"
        />
      </picture>
    </div>

    <!-- 2. Bullet points -->
    <div class="bullet-container relative">
      <div class="bullet-points relative">
        <div class="first-title second">
          <span>Por qué Velvetik</span>
          <h2 class="text-dark">Beneficios de vender en Velvetik</h2>
        </div>

        <div class="bullets-wrapper">
          <div
            class="bullet"
            v-for="bullet in bulletPoints"
            :key="bullet.title"
          >
            <i class="pi pi-heart"></i>
            <div class="bullet-content">
              <h3>{{ bullet.title }}</h3>
              <p>{{ bullet.description }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Background image -->
      <div class="mask-image">
        <div class="overlay"></div>
        <img
          src="@/assets/images/landing-provider/mask-image.webp"
          alt="Velvetik background"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
  const bulletPoints = [
    {
      title: "Acceso al mercado Norte Americano",
      description:
        "Llegue a millones de consumidores dentro del mercado Estadounidense obteniendo diversas oportunidades de venta y crecimiento.",
    },
    {
      title: "Posicionamiento de marca",
      description:
        "Posicione su marca de forma acelerada e impactante en un mercado digital que cuenta con productos de la mejor calidad para el sector de moda fetish.",
    },
    {
      title: "Comercio electrónico + IA",
      description:
        "Nuestra herramienta de Inteligencia Artificial piensa como su mejor vendedor proporcionando una experiencia de venta intuitiva y personalizada.",
    },
    {
      title: "Acompañamiento e internacionalización",
      description:
        "Utilice las métricas de venta de sus productos para tomar decisiones basado en la data y crecer su marca en la dirección correcta.",
    },
    {
      title: "Acompañamiento en Marketing",
      description:
        "Obtenga el apoyo que necesita de nuestros expertos en comercio electrónico para vender y exhibir sus productos con éxito.",
    },
  ];
</script>

<style scoped>
  .first-title span {
    color: var(--text-gray);
    /* Paragraph Bold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 150% */
    letter-spacing: 5.04px;
    text-transform: uppercase;
  }

  .first-title h2 {
    /* Mobile/display 2 */
    font-family: "Inter";
    font-size: 32px;
    font-weight: 600;
    line-height: 40px; /* 120% */
    margin-top: 8px;
  }

  .first-title.second h2 {
    margin-bottom: 0px;
  }

  .first-title.first h2 {
    margin-bottom: 40px;
  }

  .first-title.first {
    padding: 0 24px;
    margin-top: 80px;
  }

  /* Bullet Points */
  .bullet-container {
    padding: 80px 24px;
    width: 100%;
  }

  .bullet {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .bullet i {
    font-size: 24px;
    color: var(--primary);
    margin-right: 16px;
  }

  .bullet-content h3 {
    color: var(--primary);
    /* Paragraph Bold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin: 0;
  }

  .bullet-content p {
    color: var(--primary);
    /* Caption Medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    margin: 0;
    margin-top: 8px;
  }

  .mask-image {
    position: absolute;
    height: 100%;
    width: 100vw;
    margin-left: -24px;
    top: 0;
    z-index: -1;
  }

  .mask-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }

  .mask-image .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(225, 225, 225, 0.96);
  }

  .bullets-wrapper {
    margin-top: 48px;
  }

  @media (min-width: 768px) {
    .wrapper {
      max-width: 1368px;
    }

    .screenshot-wrapper {
      position: absolute;
      right: 0;
      bottom: -7px;
    }

    /** Rule for .first-title that applies only to the first of this class */
    .first-title.first {
      min-height: 768px;
      padding: 120px 96px;
    }

    .bullet-container {
      padding: 24px 0;
    }

    .first-title h2 {
      /* Display 2 */
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      line-height: 50px; /* 125% */
      font-weight: 600;

      max-width: 428px;
    }

    .first-title.second h2 {
      margin-bottom: 48px;
    }

    .bullet-container {
      padding: 120px 96px;
    }

    .mask-image {
      margin-left: -112px;
    }
    /** Arange bullet points as cards with 3 per row */
    .bullets-wrapper {
      padding-left: 296px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 40px;
      column-gap: 24px;
    }

    .bullet {
      margin-right: 24px;
      margin-bottom: 40px;
      max-width: 276px;
    }
  }
</style>
