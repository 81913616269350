<template>
  <nav class="nav-wrapper" :class="{ scrolled: isScrolled }">
    <img :src="Logo" alt="logo" />
    <div class="hamburger-wrapper">
      <i class="pi pi-bars accent-color" @click="menuVisible = true" />
      <Sidebar position="right" v-bind:visible="menuVisible">
        <template #closeicon>
          <i class="pi pi-times" @click="menuVisible = false" />
        </template>
        <template #header>
          <a href="https://wa.me/573103088636" target="_blank">
            <button class="cta-landing">Contactar</button>
          </a>
        </template>
        <template #default>
          <div class="mobile-nav-items">
            <a href="#why-velvetik" @click="menuVisible = false">Beneficios</a>
            <a href="#how-it-works" @click="menuVisible = false"
              >Como Funciona</a
            >
            <a href="#about-us" @click="menuVisible = false">Sobre Nosotros</a>
          </div>
        </template>
      </Sidebar>
    </div>
    <div class="desktop-wrapper">
      <a href="#why-velvetik">Beneficios</a>
      <a href="#how-it-works">Como Funciona</a>
      <a href="#about-us">Sobre Nosotros</a>

      <a href="https://wa.me/573103088636" target="_blank">
        <button class="cta-landing">Contactar</button>
      </a>
    </div>
  </nav>
</template>

<script setup>
  import Logo from "@/assets/logo.svg";
  import Sidebar from "primevue/sidebar";
  import { ref, onMounted, onUnmounted } from "vue";

  const isScrolled = ref(false);
  const menuVisible = ref(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      isScrolled.value = true;
    } else {
      isScrolled.value = false;
    }
  };

  onMounted(() => {
    window.addEventListener("scroll", handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
  });
</script>

<style scoped>
  nav {
    position: fixed;
    width: 100%;
    z-index: 1;
    border-bottom: 1px solid var(--secondary);
  }

  .nav-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background-color: transparent; /* Ensure the default state has a background color for smooth transition */
    transition: background-color 0.2s ease; /* Adjust timing and easing to your preference */
  }

  /* New styles for the scrolled state */
  .nav-wrapper.scrolled {
    background-color: #000; /* Replace #yourSolidColor with your desired color */
    border-bottom: none;
  }

  .nav-wrapper img {
    width: 163px;
    height: 24px;
    flex-shrink: 0;
  }

  .nav-wrapper .hamburger-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-wrapper .hamburger-wrapper i {
    font-size: 30px;
    flex-shrink: 0;
  }

  .desktop-wrapper {
    display: none;
  }

  .mobile-nav-items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
  }

  .mobile-nav-items a {
    color: #ececec;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
    border-bottom: 1px solid var(--secondary);
    padding-bottom: 8px;
  }

  @media (min-width: 768px) {
    .hamburger-wrapper {
      display: none !important;
    }

    .desktop-wrapper {
      display: flex;
      align-items: center;
      gap: 32px;
    }

    .nav-wrapper {
      padding: 12px 48px;
    }

    .desktop-wrapper a {
      color: #ececec;
      font-family: "Inter";
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      text-decoration: none;
    }

    .desktop-wrapper a:hover {
      opacity: 0.8;
    }
  }
</style>

<style>
  .p-sidebar-right .p-sidebar {
    border: none;
  }

  .cta-landing {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: 2px solid var(--secondary);
    background: transparent;
    width: fit-content;
    height: 48px;
    padding: 12px 16px;

    /* Label */
    font-family: "Inter" !important;
    color: #ececec;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 120% */
  }

  .cta-landing:hover {
    opacity: 0.8;
    cursor: pointer;
  }
</style>
